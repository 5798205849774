import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store/index';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    meta: {
      auth: true
    },
    name: 'AppRouter',
    component: () => import('@/layouts/AppLayout.vue'),
    redirect: '/rifas',
    children: [
      {
        path: 'rifas',
        name: 'ListarRifas',
        meta: {
          title: 'Minhas Rifas'
        },
        component: () => import('@/views/rifa/admin/Index.vue'),
      },
      {

        path: 'admin',
        name: 'RifaRouter',
        component: () => import('@/views/rifa/admin/Router.vue'),
        children: [
          {
            path: 'criar-rifa',
            name: 'CriarRifaView',
            meta: {
              title: 'Criar Rifa'
            },
            component: () => import('@/views/rifa/admin/criar-rifa/NovaRifa.vue'),
          },
          {
            path: 'rifa/:codRifa',
            name: 'EditarRifa',
            component: () => import('@/views/rifa/admin/editar/Index.vue'),
            meta: {
              title: 'Alterar Rifa'
            },
            children:[
            
            ]
          },
          {
            path: 'rifa/:codRifa/pagamento',
            name: 'PagamentoRifa',
            component: () => import('@/views/rifa/admin/checkout/Index.vue'),
            meta: {
              title: 'Pagamento publicação Rifa'
            },
          }
        ]
      },
      {
        path: 'ranking',
        name: 'RankingView',
        component: () => import('@/views/ranking/Index.vue'),
        meta: {
          title: 'Ranking'
        },
      },
      {
        path: 'vendas',
        name: 'VendasRouter',
        component: () => import('@/views/vendas/Index.vue'),
        meta: {
          title: 'Minhas Vendas'
        },
      },
      {
        path: 'carteira',
        name: 'CarteiraRouter',
        component: () => import('@/views/carteira/Index.vue'),
        meta: {
          title: 'Minha Carteira'
        },
      },
      {
        path: 'afiliados',
        name: 'AfiliadosView',
        component: () => import('@/views/afiliados/Index.vue'),
        meta: {
          title: 'Afiliados'
        },
      },
      {
        path: 'ajuda',
        name: 'AjudaView',
        component: () => import('@/views/ajuda/Index.vue'),
        meta: {
          title: 'Ajuda'
        },
      },
      {
        path: 'meus-dados',
        name: 'MeusDadosRouter',
        component: () => import('@/views/meus-dados/Index.vue'),
        meta: {
          title: 'Meus Dados'
        },
      },
    ]
  },
  {
    path: 'rifa',
    name: 'RifaRouter',
    component: () => import('@/layouts/ExternoLayout.vue'),
    children: [
      {
        path: '/rifa/:codRifa',
        name: 'VisualizarRifaCompra',
        component: () => import('@/views/rifa/venda/rifa/Index.vue'),
        meta: {
          title: 'Rifa'
        },
      },
      {
        path: '/rifa/:codRifaBilhetePedido/checkout/:codRifa',
        name: 'CheckoutRifaPedido',
        component: () => import('@/views/rifa/venda/checkout/Index.vue'),
        meta: {
          title: 'Checkout Reserva Rifa'
        },
      }
    ]
  },
  {
    path: 'login',
    name: 'LoginRouter',
    component: () => import('@/layouts/LoginLayout.vue'),
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'LoginView',
        component: () => import('@/views/login/Index.vue'),
      },
      {
        path: '/login/confirmacao-recuperacao-senha/:codRecuperacaoSenha',
        name: 'ConfirmarRecuperacaoSenhaView',
        component: () => import('@/views/login/components/confirmar-recuperacao-senha/ConfirmarRecuperacaoSenha.vue'),
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters.usuarioEstalogado) {
      const title = to.meta && to.meta.title ? to.meta.title : '';
      // If the route has a title, set it as the page title of the document/page
      if (title) {
        document.title = `${process.env.VUE_APP_TITLE_NAME} ${title}`
      } else {
        document.title = `${process.env.VUE_APP_TITLE}`
      }

      next()
      return
    }

    next('/login')
  } else {

    const title = to.meta && to.meta.title ? to.meta.title : '';
    // If the route has a title, set it as the page title of the document/page
    if (title) {
      document.title = `${process.env.VUE_APP_TITLE_NAME} ${title}`
    } else {
      document.title = `${process.env.VUE_APP_TITLE}`
    }

    next()
  }
})


export default router
