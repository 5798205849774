import { httpClient } from '@/core/http/api.http.service'
import store from '@/store'
import router from '@/router'
import { AUTH_LOGOUT_REQUEST } from '@/store/actions/action';

const STATUS_CODE = {
    OK: 200,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    INTERNAL_SERVER_ERROR: 500
}

httpClient.interceptors.response.use(undefined, function (error) {
    if (error) {
        if (error.response.status === STATUS_CODE.UNAUTHORIZED) {
            store.dispatch(AUTH_LOGOUT_REQUEST)
            return router.push('/login')
        }
    }
});