import Vue from 'vue'
import Vuex from 'vuex'
import { AUTH_LOGOUT_SUCCESS, AUTH_LOGOUT_REQUEST, AUTH_REQUEST, AUTH_SUCCESS, SET_DRAWER } from './actions/action';
import apiHttpService, { httpClient } from '@/core/http/api.http.service';
import { keyCompanyName, limparStorage } from '@/core/utils/auth.util';

Vue.use(Vuex)

const token = localStorage.getItem(`${keyCompanyName}token`)
const codigo = localStorage.getItem(`${keyCompanyName}codigo`)
const nome = localStorage.getItem(`${keyCompanyName}nome`)
const logo = localStorage.getItem(`${keyCompanyName}logo`)

import notificacao from './modules/notificacao'

export default new Vuex.Store({
  state: {
    drawer: true,
    usuarioLogado: {
      codigo: codigo,
      token: token,
      nome: nome,
      logo: logo
    }
  },
  getters: {
    nomeUsuarioLogado: state => state.usuarioLogado.nome,
    codUsuarioLogado: state => state.usuarioLogado.codigo,
    logoUsuarioLogado: state => state.usuarioLogado.logo,
    usuarioEstalogado: state => !!state.usuarioLogado.token,
    temLogoUsuarioLogado: state => !!state.usuarioLogado.logo ,
    token: state => state.usuarioLogado.token,
    showDrawer: (state) => state.drawer,
    usuarioLogadoAlias: (state) => {
      if (!state.usuarioLogado.nome)
        return '';

      const parts = state.usuarioLogado.nome.split(' ');

      if (parts.length === 0) {
        return parts[0].charAt(0).toUpperCase();
      }

      const pt1 = parts[0];
      const pt2 = parts[parts.length - 1];
      return `${pt1.charAt(0)}${pt2.charAt(0)}`.toUpperCase()
    }
  },
  actions: {
    [AUTH_REQUEST]: ({ commit, dispatch }: any, payload: any) => {
      return new Promise((resolve, reject) => {
        const authorizationKey = 'Authorization';
        apiHttpService.post<any>(`/authorization`, payload).then(result => {
          if (result.success) {
            httpClient.defaults.headers.common[authorizationKey] = httpClient.defaults.headers.common[authorizationKey] = `bearer ${result.data.token}`;
            commit(AUTH_SUCCESS, result.data)
          }
          resolve(result);
        }).catch(err => {
          reject(err)
        });
      });
    },
    [AUTH_LOGOUT_REQUEST]: ({ state, commit, dispatch }: any, payload: any) => {
      return new Promise((resolve, reject) => {
        limparStorage();
        commit(AUTH_LOGOUT_SUCCESS);
        resolve(true);
      })
    },
  },
  mutations: {
    [AUTH_SUCCESS]: (state: any, payload: any) => {
      state.usuarioLogado = payload;
      localStorage.setItem(`${keyCompanyName}codigo`, payload.codigo)
      localStorage.setItem(`${keyCompanyName}token`, payload.token)
      localStorage.setItem(`${keyCompanyName}nome`, payload.nome)

      if (payload.logo)
        localStorage.setItem(`${keyCompanyName}logo`, payload.logo)
    },
    [AUTH_LOGOUT_SUCCESS]: (state: any, payload: any) => {
      //
    },
    [SET_DRAWER](state, payload) {
      state.drawer = !state.drawer;
    }
  },
  modules: {
    notificacao
  }
})
