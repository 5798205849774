import { Base64 } from 'js-base64';

const AUTH_LOCAL_STORAGE_KEY = 'rifando-io-lembrar-acesso'
const keyCompanyName = process.env.VUE_APP_COMPANY_NAME ? `${process.env.VUE_APP_COMPANY_NAME.toLowerCase()}-` : '';


interface IDadosAcesso {
    email: string,
    senha: string,
    lembrarAcesso: boolean
}

const lembrarDadosAcesso = (model: IDadosAcesso): void => {
    const data = JSON.stringify(model);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, Base64.encode(data));
}

const limparDadosAcesso = (): void => {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
}

const obterDadosAcesso = (): IDadosAcesso | null => {
    const data = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    if (!data) return null
    return JSON.parse(Base64.decode(data));
}


const limparStorage = (): void => {
    localStorage.removeItem(`${keyCompanyName}codigo`);
    localStorage.removeItem(`${keyCompanyName}token`);
    localStorage.removeItem(`${keyCompanyName}nome`);
    localStorage.removeItem(`${keyCompanyName}ehPrimeiroAcesso`);
    localStorage.removeItem(`${keyCompanyName}cpf`);
    localStorage.removeItem(`${keyCompanyName}logo`);
}

export {
    lembrarDadosAcesso,
    limparDadosAcesso,
    obterDadosAcesso,
    limparStorage,
    keyCompanyName
}