import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import authToken from '@/plugins/auth.plugin'
import "@/filters/index";
import VueHead from 'vue-head'

Vue.use(VueHead)
Vue.config.productionTip = false

Vue.component('app-navbar', () => import('@/components/navbar/Navbar.vue'))
Vue.component('v-input-currency', () => import("@/components/shared/currency/IndexView.vue"))
Vue.component('v-input-date-picker', () => import("@/components/shared/datepicker/DatePicker.vue"));
Vue.component('v-input-date-picker-field', () => import("@/components/shared/datepicker/DatePickerField.vue"));
Vue.component('app-rifa-promocao', () => import("@/components/rifa/promocao/Index.vue"));
Vue.component('app-rifa-taxa-publicacao', () => import("@/components/rifa/taxa-publicacao/Index.vue"));
Vue.component('app-notificacao', () => import("@/components/shared/notificacao/Index.vue"));

//Auth Plugin
Vue.use(authToken)

//External plugins
//Mask
import VueMask from 'v-mask'
Vue.use(VueMask);

import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard)


// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles 
import 'vuetify/dist/vuetify.min.css'

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo)

Vue.component('app-paging', () => import("@/components/shared/paging/Paging.vue"))

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  position: 'bottom-right',
  toastClassName: ''
};


Vue.use(Toast, options);


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
