import {httpClient} from '@/core/http/api.http.service';
import store from '@/store/index'
import '@/core/interceptor/auth.interceptor'

export default {
    install(Vue: any) {
        const token = store.getters.token;

        if (token) {
            httpClient.defaults.headers.common['Authorization'] = `bearer ${token}`;
        }
    }
}