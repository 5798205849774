import ApiHttpService from '@/core/http/api.http.service'

import { CLEAR_NOTIFICATION, LOAD_NOTIFICATION, SET_NOTIFICATION } from "../actions/action";

const module = {
    state: {
        notificacao: [],
        total: 0,
        totalNaoLida: 0,
        pagina: 1,
        totalPagina: 0
    },
    getters: {
        notificacoes: (state: any) => state.notificacao,
        qtdNaoLidas: (state: any) => state.totalNaoLida,
        qtdTotal: (state: any) => state.total,
        paginaAtual: (state: any) => state.pagina,
        exibirBtnCarregarMais: (state: any) => state.pagina <= state.totalPagina
    },
    actions: {
        [LOAD_NOTIFICATION]: ({ commit, dispatch }: any, payload: any) => {
            return new Promise((resolve, reject) => {
                const paging = {
                    page: payload && payload.pagina || 1,
                    pageSize: 10,
                    totalPages: 0,
                    totalRecords: 0,
                    orderBy: "Data",
                    orderType: "desc",
                }

                ApiHttpService.getWithPaging<any>(`/notificacao?`, paging).then(result => {
                    if (result.success) {
                        commit(SET_NOTIFICATION, result.data);
                        resolve(result);
                    } else {
                        resolve([])
                    }
                }).catch(err => {
                    reject(err)
                });
            })
        },
    },
    mutations: {
        [SET_NOTIFICATION](state: any, payload: any) {
            state.total = payload.total
            state.totalNaoLida = payload.totalNaoLida
            state.pagina = payload.notificacao.page
            state.totalPagina = payload.notificacao.totalPages

            if (payload.limpar) {
                state.notificacao = []
            }

            payload.notificacao.data.forEach((item: any) => {
                const index = state.notificacao.findIndex((n: any) => n.codNotificacao === item.codNotificacao);

                if (index !== -1) {
                    state.notificacao[index] = item;
                } else {
                    state.notificacao.push(item);
                }
            })

        },
        [CLEAR_NOTIFICATION](state: any, payload: any) {
            state.notificacao = []
            state.total = 0
            state.totalNaoLida = 0
            state.pagina = 1
            state.totalPagina = 0
        }
    },

}


export default module;