/**
 * Normalize url with parameters and filters
 * @param url 
 * @param paging 
 * @param filtro 
 */
export const normalizeUrlWithParameters = function normalizeUrlWithParameters(
    url: string,
    paging: any,
    filtro: any): string {

    for (const key in paging) {
        if (Object.prototype.hasOwnProperty.call(paging, key)) {
            const prop = paging[key];

            if (prop && (key === 'page' || key === 'pageSize' || key === 'orderBy' || key === 'orderType')) {
                url += `${key}=${prop}&`;
            }
        }
    }

    for (const key in filtro) {
        if (Object.prototype.hasOwnProperty.call(filtro, key)) {
            const prop = filtro[key];

            if (prop) {
                url += `${key}=${prop}&`;
            }
        }
    }

    const start = 0;
    const end = (url.length - 1);

    return url.substring(start, end);
}

export default {
    normalizeUrlWithParameters
}